
@for (entry of entries; track entry.body) {
  <div class="eq-qanda">
    <h3 class="ed-question" (click)="setActive($index)">
      <div>{{ entry.head }}</div>
      <app-icon>{{ $index === active ? 'expand_more' : 'expand_less' }}</app-icon>
    </h3>
    <div class="ed-answer" [@collapse]="$index === active ? 1 : 0">
      <div class="ed-inner" [innerHTML]="entry.body"></div>
    </div>
  </div>
}
