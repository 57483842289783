import { Component, Input, OnInit } from '@angular/core'
import { collapseTrigger } from 'src/static/animations/collapse.animation'
import { IconComponent } from 'src/app/elements/icon/icon.component'

export interface AccordionEntry {
  head: string
  body: string
  open?: true
}

@Component({
  standalone: true,
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrl: './accordion.component.sass',
  animations: [collapseTrigger],
  imports: [
    IconComponent,
  ],
})
export class AccordionComponent implements OnInit {
  @Input({ required: true }) entries!: AccordionEntry[]
  @Input({ required: false }) active = 0

  setActive (n: number) {
    this.active = n === this.active
      ? -1
      : n
  }

  ngOnInit(): void {
    this.entries.forEach((entry, n) => {
      if (entry.open) {
        this.setActive(n)
      }
    })
  }
}
