import { animate, state, style, transition, trigger } from '@angular/animations'

export const collapseTrigger = trigger('collapse', [
  state('0', style({ 'height': '0' })),
  state('1', style({ 'height': '*' })),
  transition('0 => 1', animate('250ms ease-in-out')),
  transition('1 => 0', animate('250ms ease-in-out')),
])

const collapseEnterTransition = transition(':enter', [
  style({ height: 0 }),
  animate('350ms ease-out', style({ height: '*' })),
])

const collapseLeaveTransition = transition(':leave', [
  animate('100ms ease-in', style({ height: 0 })),
])

export const collapseEnterLeave = trigger('collapseEL', [
  state('void', style({ height: '*' })),
  collapseEnterTransition,
  collapseLeaveTransition,
])

export const collapseEnter = trigger('collapseEnter', [
  state('void', style({ height: '*' })),
  collapseEnterTransition,
])

export const collapseLeave = trigger('collapseLeave', [
  state('void', style({ height: '*' })),
  collapseLeaveTransition,
])
